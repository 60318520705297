.Home {
    min-height: 100%;
    height:auto;
    overflow: hidden;
    margin: auto;
}

.inner70Div {
    width: 70%;
    margin: auto;
    overflow: hidden;
}

div.selfinfoBrowser {
    float:right;
    margin-left: 2em;
    margin-top: 1em;
}
div.selfinfoMobile {
    text-align: center;
}
div.contactinfo {
    line-height: 1.4em;
    text-align: center;
}
div.contactinfoheader {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    border-bottom: solid thin #000000;
    margin-bottom: 5px;

}
img.selfpicture {
    margin-bottom: 1em;
    margin-top: 1em;
}

.title {
    margin: 0.5em 0 10px  0 ;
    text-align: left;
    font-size: x-large;
    font-weight: bold;
}
.aboutme {
    margin-bottom: 1.3em;
    margin-right: 1.3em;
}
.aboutMeLink {
    color: inherit;
    text-decoration: underline;
}

.workExperience {
    margin-top: 10px;
    margin-bottom: 10px;
}

.socialLink {
    margin-left: 5px;
    margin-right: 5px;
    height: 30px;
    width: 30px;
}

caption {
    font-weight: bold;
    caption-side: bottom;
    font-size: small;
    margin-bottom: .8em;

}



