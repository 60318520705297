.Involvement {
    min-height: 100%;
    height:auto;
    overflow: hidden;
    margin: auto;
}

.inner70Div {
    width: 70%;
    margin: auto;
    overflow: hidden;
}

.mtTitle {
    margin-top: 1em;
}

.title {
    text-align: left;
    margin: .5em 0 0 0;
    font-size: x-large;
    font-weight: bold;
}
.paragraph {
    margin-top: 15px;
    margin-bottom: 15px;
}
