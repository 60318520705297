div.banner {
	color: #EEEEEE;
	min-height: 10px;
	overflow: hidden;
	background-color: black ; /*#8597a6;*/
	border-bottom: solid thin #e0e0e0;
}

.innerNavDivBrowser {
	width: 70%;
	display:flex;
	margin: auto;
	overflow: hidden;
}
.innerNavDivMobile {
	width: 70%;
	display: block;
	margin: auto;
	overflow: hidden;
}

div.titlesBrowser {
	display:block;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: left;
}
div.titlesMobile {
	display:block;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
}
div.maintitle {
	font-size: x-large;
	font-weight: bold;
	text-align: left;
}
div.subtitle {
	font-size: large;
	font-weight: bold;
	margin-left: 0;
	text-align: left;
}

div.navlinksBrowser {
	width: 100%;
	float: right;
	margin-bottom: 21.5px;
	margin-top: auto;
	text-align: right;
}
div.navlinksMobile {
	width: 100%;
	text-align: center;
	margin: auto auto 21.5px;
}
a.navlink {
	color: #EEEEEE;

	margin: 0 4px 0 4px;
	text-decoration: none;
	font-size: small;
	font-weight: 700;
}
a.navlink:hover {
	text-decoration: underline; 
}